<template>
	<div class="animate__animated animate__zoomIn bg-white md:px-10 py-7 default-shadow rounded-2xl">
		<div class="md:px-10 flex justify-center">
			<h1 v-if="header" class="font-bold text-gray07 text-center">
				Get a Loan
			</h1>

			<div v-else class="flex bg-grayF5" style="padding: 2px; border-radius: 14.5px">
				<div v-for="(tab, index) in tabs" :key="index" @click="() => switchTab(index)" :class="
					tabIndex === index
						? `${tab.bgColor} text-white`
						: 'bg-grayF5 text-gray8D'
				" class="py-2 px-4 md:px-12 rounded-xl cursor-pointer">
					<p class="text-xs md:text-sm">{{ tab.title }}</p>
				</div>
			</div>
		</div>

		<div v-if="tabIndex === 0" class="mx-4 md:mx-0">
			<div class="mt-6 md:mt-10">
				<label for="borrowAmount" class="text-gray07 text-xs md:text-sm">
					How much?
				</label>

				<input id="borrowAmount"
					class="bg-grayF5 text-gray07 text-xs font-medium md:text-base border-0 outline-none mt-3"
					type="text"
					min="100000"
					max="10000000"
					maxlength="10"
					@keypress="validate_onlyNumberKeys($event)"
					:value="Number(loanPrincipal.amount).toLocaleString()"
					@input="event => { changeText(event.target.value, 'loanPrincipal', 'amount')}" 
				/>
				<span v-if="errorMsg.loanPrincipal" class="text-xxs text-secondary">Amount can not be less than ₦100,000</span>
			</div>

			<div class="flex items-center w-full mt-6 md:mt-10">
				<div class="w-1/3 md:w-1/2">
					<label for="borrowDuration" class="text-gray07 text-xs md:text-sm">
						How many months?
					</label>
					<input id="borrowDuration"
						class="bg-grayF5 text-gray07 text-xs md:text-base border-0 outline-none mt-3 font-medium text-center"
						type="number"
						min="1"
						max="18"
						v-model="loanPrincipal.months"
						@input="event => { checkDuration(event.target.value)}"
					/>
					<span v-if="errorMsg.duration" class="text-xxs text-secondary">Tenor can not be less than 3 months</span>

				</div>
				<div class="w-2/3 md:w-1/2 flex flex-col items-end">
					<p class="text-gray07 text-xs md:text-sm">Monthly repayment</p>
					<p class="text-gray07 text-xl md:text-2xl font-medium mt-6">
						₦{{ Number(calculateLoanMonthlyRepayment).toLocaleString() }}
					</p>
				</div>
			</div>

			<div class="mt-6 md:mt-10 flex justify-center">
				<a href="https://bit.ly/download-sycamore" id="minMax" @click="eventLog(`${page}_request_loan_btn`)" :class="errorMsg.loanPrincipal || errorMsg.duration ? 'disable-link' : ''" target="_blank" >
					<button class="px-8 py-3 text-xs md:text-sm bg-grayF3 text-secondary rounded-md">
						<div class="flex items-center">
							<span class="mr-3 text-xs md:text-sm">
								<arrow-up-right-red />
							</span>
							Request loan
						</div>
					</button>
				</a>
			</div>
		</div>

		<div v-else class="mx-4 md:mx-0">
			<div class="mt-6 md:mt-10">
				<label for="investAmount" class="text-gray07 text-xs md:text-sm">
					How much do you want to lend?
				</label>
				<input id="investAmount"
					class="bg-grayF5 text-gray07 text-xs md:text-base border-0 outline-none mt-3 font-medium"
					type="text"
					min="100000"
					max="5000000"
					maxlength="12"
					:value="Number(investmentPrincipal.amount).toLocaleString()"
					@keypress="validate_onlyNumberKeys($event)"
					@input="event => { changeText(event.target.value, 'investmentPrincipal', 'amount')}"
				/>
				<span v-if="errorMsg.investmentPrincipal" class="text-xxs text-secondary">Amount can not be less than  ₦100,000</span>
			</div>
			<div class="flex items-center w-full mt-6 md:mt-10">
				<div class="w-1/3 md:w-1/2">
					<label for="borrowDuration" class="text-gray07 text-xs md:text-sm">
						How many months?
					</label>
					<input id="borrowDuration"
						class="bg-grayF5 text-gray07 border-0 outline-none mt-3 text-xs md:text-base text-center font-medium"
						type="number"
						min="1"
						max="18"
						v-model="investmentPrincipal.months"
						@input="event => { checkDuration(event.target.value)}"
					/>
					<span v-if="errorMsg.duration" class="text-xxs text-secondary">Tenor can not be less than 3 months</span>
				</div>
				<div class="w-2/3 md:w-1/2 flex flex-col items-end">
					<p class="text-gray07 text-xs md:text-sm">Total at maturity</p>
					<p class="text-gray07 text-xl md:text-2xl mt-6 font-medium">
						₦{{
							Number(Math.floor(calculateInvestmentPayment)).toLocaleString(0)
						}}
					</p>
				</div>
			</div>
			<div class="mt-6 md:mt-10 flex justify-center">
				<a href="https://bit.ly/download-sycamore" id="minMax" :class="errorMsg.investmentPrincipal || errorMsg.duration ? 'disable-link' : ''" target="_blank" @click="eventLog(`${page}_create_investment_btn`)">
					<button class="px-8 py-3 text-sm bg-grayF3 text-primary rounded-md">
						<div class="flex items-center text-xs md:text-sm">
							<span class="mr-3">
								<arrow-up-right-green /> 
							</span>
							Create investment
						</div>
					</button>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import ArrowUpRightRed from "@/svg/ArrowUpRightRed.vue";
import ArrowUpRightGreen from "@/svg/ArrowUpRightGreen.vue";
import { mapState, mapActions } from "pinia";
import { sycamoreContacts } from "@/store";

export default {
  components: {
    ArrowUpRightRed,
    ArrowUpRightGreen,
  },
  data() {
    return {
      tabIndex: 0,
      loanPrincipal: {
        amount: 100000,
        months: 3,
      },
      investmentPrincipal: {
        amount: 100000,
        months: 3,
      },
      errorMsg: {
        loanPrincipal: false,
        investmentPrincipal: false,
        duration: false,
      },

      DASHBOARD_URL: process.env.VUE_APP_DASHBOARD_URL,
    };
  },
  props: {
    header: String,
    page: String,
  },

  computed: {
    ...mapState(sycamoreContacts, [
      "loanProducts",
      "investmentProducts",
      "tcProducts",
      "isFetchedOffers",
    ]),

    investmentInterestRate() {
      if (this.isFetchedOffers) {
        const product = this.investmentProducts.find((product) => {
          return (
            this.investmentPrincipal.amount >= product.minimum_amount &&
            this.investmentPrincipal.amount <= product.maximum_amount
          );
        });
        return product && product?.interest_rate;
      }
      return 0;
    },

    loanInterestRate() {
      if (this.isFetchedOffers) {
        const individualLoans = this.loanProducts.filter((product) =>
          product.loan_type.includes("individual")
        );
        const product = individualLoans.find((product) => {
          return (
            this.loanPrincipal.amount >= product.minimum_amount &&
            this.loanPrincipal.amount <= product.maximum_amount
          );
        });
        return product ? product.rate : 0;
      }
      return 0;
    },

    calculateLoanMonthlyRepayment() {
      const monthlyPayment =
        this.loanPrincipal.amount / this.loanPrincipal.months;
      const interest = (this.loanInterestRate / 100) * this.loanPrincipal.amount;
      const totalPayment = monthlyPayment + interest;
      return Math.round(totalPayment);
    },

    calculateInvestmentPayment() {
      const grossInterestAccruable =
        ((this.investmentPrincipal.amount * (this.investmentInterestRate / 100)) / 365) *
        (this.investmentPrincipal.months * 30);
      const withholdingTax = grossInterestAccruable * 0.1;
      const netInterestAccruable = grossInterestAccruable - withholdingTax;
      return netInterestAccruable + Number(this.investmentPrincipal.amount);
    },

		tabs() {
			return [
				{
					title: "Borrow",
					bgColor: "bg-secondary"
				},
				{
					title: "Invest",
					bgColor: "bg-primary"
				}
			];
		},
	},

  methods: {
    ...mapActions(sycamoreContacts, ["getProductOffer"]),
      switchTab(value) {
        this.tabIndex = value;
    },

    eventLog(name) {
      window?.trackEvent(name);
    },

		validate_onlyNumberKeys(event) {
			if (!/\d/.test(event.key)) return event.preventDefault();
		},

    routeToAuth(page) {
      const path = this.tabIndex === 0 ? "apply/loans" : "apply/investment";
      const amount =
        this.tabIndex === 0 ? this.loanPrincipal.amount : this.investmentPrincipal.amount;
      const duration =
        this.tabIndex === 0 ? this.loanPrincipal.months : this.investmentPrincipal.months;
      if (page === "login") {
        window.location.href = `${this.DASHBOARD_URL}${path}?amount=${amount}&duration=${duration}`;
      } else {
        window.location.href = `${this.DASHBOARD_URL}register?redirect=amount%3d${amount}%26duration%3d${duration}`;
      }
    },

		roundToTwoDecimal(num) {
			return +(Math.round(num + "e+2") + "e-2");
		},

		changeText(value = "", type, name) {
			const numberRegex = /^[,0-9]+$/;
			if (value?.match(numberRegex)) {
				const removeComma = value.replace(/,/gi, "");
				this[type][name] = removeComma;
				this.minMax(removeComma, type)
			}
		},

		checkDuration(value) {
			if (value < 3) {
				this.errorMsg.duration = true
			} else {
				this.errorMsg.duration = false
			}
		},

		minMax(value, type) {
			if (value < 100000) {
				this.errorMsg[type] = true
			} else {
				this.errorMsg[type] = false
			}
		}

	},
  created() {
    if (!this.isFetchedOffers) {
      this.getProductOffer();
    }
  },
};
</script>

<style scoped>
.disable-link {
	pointer-events: none;
}
</style>
