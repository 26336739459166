<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.521 0.39579H1.47942C1.09276 0.396235 0.722059 0.550032 0.448648 0.823442C0.175238 1.09685 0.0214408 1.46755 0.0209961 1.85421V14.1458C0.0214408 14.5325 0.175238 14.9031 0.448648 15.1766C0.722059 15.45 1.09276 15.6038 1.47942 15.6042H16.521C16.9077 15.6038 17.2784 15.45 17.5518 15.1766C17.8252 14.9031 17.979 14.5325 17.9794 14.1458V1.85421C17.979 1.46755 17.8252 1.09685 17.5518 0.823442C17.2784 0.550032 16.9077 0.396235 16.521 0.39579ZM8.39839 8.53686L4.96089 11.2869C4.8904 11.3433 4.80947 11.3854 4.72272 11.4106C4.63597 11.4358 4.54511 11.4436 4.45533 11.4337C4.36555 11.4237 4.2786 11.3962 4.19947 11.3526C4.12033 11.3091 4.05056 11.2503 3.99413 11.1798C3.93771 11.1093 3.89574 11.0283 3.87062 10.9415C3.8455 10.8548 3.83773 10.7639 3.84775 10.6741C3.85777 10.5843 3.88538 10.4974 3.92901 10.4183C3.97264 10.3392 4.03143 10.2695 4.10202 10.2131L6.86839 8L4.10202 5.78686C4.03143 5.73049 3.97264 5.66077 3.92901 5.58167C3.88538 5.50258 3.85777 5.41566 3.84775 5.32588C3.83773 5.23611 3.8455 5.14524 3.87062 5.05847C3.89574 4.9717 3.93771 4.89073 3.99413 4.82019C4.05056 4.74965 4.12033 4.69092 4.19947 4.64736C4.2786 4.6038 4.36555 4.57626 4.45533 4.56632C4.54511 4.55638 4.63597 4.56423 4.72272 4.58942C4.80947 4.61461 4.8904 4.65666 4.96089 4.71314L8.39839 7.46314C8.47891 7.52755 8.54392 7.60924 8.58859 7.70217C8.63326 7.7951 8.65646 7.89689 8.65646 8C8.65646 8.10311 8.63326 8.2049 8.58859 8.29783C8.54392 8.39076 8.47891 8.47245 8.39839 8.53686ZM13.4685 11.4375H10.031C9.84862 11.4375 9.67375 11.3651 9.54482 11.2361C9.41588 11.1072 9.34345 10.9323 9.34345 10.75C9.34345 10.5677 9.41588 10.3928 9.54482 10.2639C9.67375 10.1349 9.84862 10.0625 10.031 10.0625H13.4685C13.6508 10.0625 13.8257 10.1349 13.9546 10.2639C14.0835 10.3928 14.156 10.5677 14.156 10.75C14.156 10.9323 14.0835 11.1072 13.9546 11.2361C13.8257 11.3651 13.6508 11.4375 13.4685 11.4375Z"
      fill="#7793F4"
    />
  </svg>
</template>

<script>
export default {
  name: "MenuDevelopers"
};
</script>
