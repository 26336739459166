<template>
  <svg
    width="59"
    height="60"
    viewBox="0 0 59 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.7"
      d="M7.74394 34.2981C6.78601 29.1484 7.70767 23.8281 10.3434 19.2927C12.9792 14.7573 17.1563 11.3039 22.1247 9.55273V26.0505L7.74394 34.2981Z"
      fill="#EAFFE7"
    />
    <path
      d="M29.5 30.2801V8.302"
      stroke="#04B200"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.661 19.291L10.3394 41.2691"
      stroke="#04B200"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.74394 34.2981C6.78601 29.1484 7.70767 23.8281 10.3434 19.2927C12.9792 14.7573 17.1563 11.3039 22.1247 9.55273V26.0505L7.74394 34.2981Z"
      stroke="#04B200"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.4994 8.302C33.3757 8.30271 37.1838 9.31507 40.5424 11.2377C43.901 13.1603 46.6921 15.9257 48.6363 19.257C50.5804 22.5883 51.6094 26.3685 51.6202 30.219C51.631 34.0696 50.6231 37.8555 48.6976 41.1974C46.7721 44.5393 43.9965 47.3201 40.6487 49.2612C37.301 51.2024 33.4986 52.2357 29.6223 52.2578C25.746 52.2799 21.9319 51.29 18.562 49.3872C15.192 47.4844 12.3844 44.7354 10.4204 41.4157"
      stroke="#04B200"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ChartPieSlice"
};
</script>
