import { defineStore } from 'pinia'
import { getContacts } from "@/api/contacts";
import { getSycamoreProducts } from '@/api/productOffers';

export const sycamoreContacts = defineStore('all', {
  state: () => ({
    contacts: null,
    socials: null,
    appUrls: null,
    isContactsSet: false,
    loading: false,


    loanProducts: [],
    investmentProducts: [],
    tcProducts: [],
    loading2: false,
    isFetchedOffers: false
  }),

  actions: {
    async getContacts(){
        try  {
            this.loading = true;
            const response = await getContacts();
            this.isContactsSet = true
            this.contacts = response.data.data.contact
            this.socials = response.data.data.socials
            this.appUrls = response.data.data.app_urls

        } catch (err) {
            this.loading = false;
        }
    },

    async getProductOffer(){
      try  {
          this.loading2 = true;
          const response = await getSycamoreProducts();
          if(response.data.success && response.status === 200) {
            this.isFetchedOffers = true
            this.loanProducts = response.data.data.loan_products
            this.investmentProducts = response.data.data.investment_products
            this.tcProducts = response.data.data.target_contribution_products
          }
          this.loading2 = false;
      } catch (err) {
          this.loading2 = false;
      }
  }
  }
})