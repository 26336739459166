<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.563 3.5H13.1248V2.8125C13.1242 2.26568 12.9067 1.74144 12.52 1.35478C12.1334 0.968125 11.6091 0.750625 11.0623 0.75H6.93732C6.3905 0.750625 5.86626 0.968125 5.4796 1.35478C5.09294 1.74144 4.87544 2.26568 4.87482 2.8125V3.5H1.43799C1.07344 3.50042 0.723949 3.64542 0.466177 3.90319C0.208405 4.16096 0.0634052 4.51046 0.0629883 4.875V15.875C0.0634052 16.2395 0.208405 16.589 0.466177 16.8468C0.723949 17.1046 1.07344 17.2496 1.43799 17.25H16.563C16.9275 17.2496 17.277 17.1046 17.5348 16.8468C17.7926 16.589 17.9376 16.2395 17.938 15.875V4.875C17.9376 4.51046 17.7926 4.16096 17.5348 3.90319C17.277 3.64542 16.9275 3.50042 16.563 3.5ZM6.24982 2.8125C6.25003 2.63023 6.32253 2.45548 6.45141 2.32659C6.5803 2.19771 6.75504 2.12521 6.93732 2.125H11.0623C11.2446 2.12521 11.4193 2.19771 11.5482 2.32659C11.6771 2.45548 11.7496 2.63023 11.7498 2.8125V3.5H6.24982V2.8125ZM16.563 4.875L16.5633 8.45052C14.2425 9.71388 11.6422 10.3755 8.99982 10.375C6.35809 10.3755 3.75834 9.71416 1.43799 8.45135V4.875H16.563ZM7.28107 8.3125C7.28108 8.13017 7.35351 7.9553 7.48244 7.82637C7.61137 7.69744 7.78623 7.62501 7.96857 7.625H10.0311C10.2134 7.625 10.3883 7.69743 10.5172 7.82636C10.6461 7.9553 10.7186 8.13016 10.7186 8.3125C10.7186 8.49484 10.6461 8.6697 10.5172 8.79864C10.3883 8.92757 10.2134 9 10.0311 9H7.96857C7.78623 8.99999 7.61137 8.92756 7.48244 8.79863C7.35351 8.6697 7.28108 8.49483 7.28107 8.3125Z"
      fill="#15C6C6"
    />
  </svg>
</template>

<script>
export default {
  name: "MenuCareer"
};
</script>
