<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2745_2420)">
<path d="M15.1847 18.7617C15.6589 18.5749 16.1185 18.3528 16.5597 18.0974V21.577C16.5597 21.7594 16.4872 21.9342 16.3583 22.0632C16.2294 22.1921 16.0545 22.2645 15.8722 22.2645C15.6898 22.2645 15.515 22.1921 15.386 22.0632C15.2571 21.9342 15.1847 21.7594 15.1847 21.577V18.7617ZM16.1953 11.1623L11.3828 8.59532C11.2222 8.5133 11.0359 8.49765 10.8639 8.55172C10.6919 8.60579 10.548 8.72527 10.4633 8.8844C10.3785 9.04353 10.3597 9.22959 10.4108 9.40249C10.4619 9.57539 10.5789 9.72129 10.7365 9.80875L14.755 11.952L16.2159 11.1734L16.1953 11.1623ZM21.6953 8.59532L11.3828 3.09532C11.2833 3.04235 11.1724 3.01465 11.0597 3.01465C10.947 3.01465 10.836 3.04235 10.7365 3.09532L0.424036 8.59532C0.314039 8.65394 0.222049 8.74136 0.157904 8.84823C0.0937601 8.9551 0.0598755 9.07739 0.0598755 9.20203C0.0598755 9.32668 0.0937601 9.44897 0.157904 9.55584C0.222049 9.66271 0.314039 9.75013 0.424036 9.80875L2.80966 11.0815V15.2426C2.80895 15.5803 2.93323 15.9063 3.15857 16.1578C4.28435 17.4116 6.80661 19.5145 11.0597 19.5145C12.4698 19.5262 13.8695 19.2708 15.1847 18.7617V12.1815L14.755 11.952L11.0597 13.9226L3.8263 10.0614L2.2081 9.20203L11.0597 4.48149L19.9112 9.20203L18.2973 10.0614H18.2922L16.2159 11.1734C16.3204 11.2338 16.4072 11.3206 16.4676 11.4251C16.5279 11.5297 16.5597 11.6483 16.5597 11.769V18.0974C17.4562 17.5799 18.2663 16.9255 18.9608 16.1578C19.1861 15.9063 19.3104 15.5803 19.3097 15.2426V11.0815L21.6953 9.80875C21.8053 9.75013 21.8973 9.66271 21.9614 9.55584C22.0256 9.44897 22.0594 9.32668 22.0594 9.20203C22.0594 9.07739 22.0256 8.9551 21.9614 8.84823C21.8973 8.74136 21.8053 8.65394 21.6953 8.59532Z" fill="#110C22"/>
</g>
<defs>
<clipPath id="clip0_2745_2420">
<rect width="22" height="22" fill="white" transform="translate(0.0596619 0.952026)"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>