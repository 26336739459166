<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M15.4824 4.5957L8.8471 10.6781L2.21176 4.5957H15.4824Z"
      fill="#26C165"
    />
    <path
      d="M2.21176 4.5957H15.4824V13.9958C15.4824 14.1424 15.4242 14.2831 15.3205 14.3868C15.2168 14.4904 15.0761 14.5487 14.9295 14.5487H2.76471C2.61806 14.5487 2.47741 14.4904 2.37372 14.3868C2.27002 14.2831 2.21176 14.1424 2.21176 13.9958V4.5957Z"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4824 4.5957L8.8471 10.6781L2.21176 4.5957"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "envelope"
};
</script>

<style></style>
