<template>
  <svg
    width="59"
    height="60"
    viewBox="0 0 59 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.7"
      d="M29 51.6374C28.5 54.1208 28 54.1208 26 53.1274C25.6332 53.2528 20.3668 49.2794 20 49.154C15.5 44.6839 10.5 43.1938 9.5 32.7635V12.3997H28.9338L29 51.6374Z"
      fill="#FFFCE0"
    />
    <path
      d="M9.21875 27.2277V13.7966C9.21875 13.3108 9.413 12.845 9.75877 12.5015C10.1045 12.1581 10.5735 11.9651 11.0625 11.9651H47.9375C48.4265 11.9651 48.8955 12.1581 49.2412 12.5015C49.587 12.845 49.7812 13.3108 49.7812 13.7966V27.2277C49.7812 46.462 33.3474 52.8345 30.0661 53.9152C29.6993 54.0406 29.3007 54.0406 28.9339 53.9152C25.6526 52.8345 9.21875 46.462 9.21875 27.2277Z"
      stroke="#CDB900"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.9998 53.6241C28.9998 53.6241 29 38.0291 29 29.4334C29 22.7813 29 12.3997 29 12.3997"
      stroke="#CDB900"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ShieldChevron"
};
</script>
