var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"absolute w-full z-20"},[_c('div',{staticClass:"flex justify-between items-center mx-20 md:px-20 py-10"},[_c('div',{staticClass:"w-32 mr-24"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"width":"100%","alt":""}})])],1),_c('div',{staticClass:"flex w-1/3 justify-between items-center text-sm"},[_vm._l((_vm.nav),function(item,index){return _c('div',{key:index,staticClass:"link-item"},[(item.path)?_c('p',{staticClass:"cursor-pointer text-gray4F py-5 font-medium capitalize"},[_c('router-link',{attrs:{"to":item.path}},[_vm._v(" "+_vm._s(item.title)+" ")])],1):_c('p',{staticClass:"cursor-pointer text-gray4F py-5 font-medium capitalize",attrs:{"to":item.path}},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.sub)?_c('div',{staticClass:"py-5 px-4 bg-white absolute -mt-2 -ml-24 rounded-lg w-auto link-item-dropdown",class:item.sub.length > 3
                  ? 'grid grid-cols-1 lg:grid-cols-2 gap-x-10 px-8'
                  : 'px-4',staticStyle:{"box-shadow":"2px 8px 48px -16px rgba(28, 37, 71, 0.2)"}},_vm._l((item.sub),function(subItem,index){return _c('router-link',{key:index,attrs:{"to":subItem.path}},[_c('div',{staticClass:"flex items-center",class:`
                  ${
                      index === item.sub.length - 1 ||
                      (item.sub.length > 3 && index === item.sub.length - 2)
                      ? 'mb-0'
                      : 'mb-7'
                  } text-default text-${subItem.hover}`},[_c('div',{staticClass:"mr-2 h-11 w-11 rounded-lg flex items-center justify-center",style:(`background-color: ${subItem.bgColor}`)},[_c(subItem.icon,{tag:"component"})],1),_c('div',[_c('p',{staticClass:"font-semibold capitalize"},[_vm._v(_vm._s(subItem.title))]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(subItem.description))])])])])}),1):_vm._e()])}),_c('a',{staticClass:"cursor-pointer text-gray4F py-5 font-medium",attrs:{"target":"_blank","href":"https://blog.sycamore.ng/"}},[_vm._v("Blog")])],2),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-1/2 flex items-center justify-end"},[_c('a',{staticClass:"px-8 py-2 text-sm bg-white text-primary border border-primary mr-5 rounded-md",attrs:{"href":"https://dashboard.sycamore.ng","onclick":"trackEvent('nav_signin_btn')","target":"_blank"}},[_vm._v(" Sign in ")]),_c('a',{staticClass:"px-8 py-2 text-sm bg-primary border border-primary text-white rounded-md",attrs:{"onclick":"trackEvent('nav_signup_btn')","href":"https://dashboard.sycamore.ng/register","target":"_blank"}},[_vm._v(" Sign up ")])])
}]

export { render, staticRenderFns }