<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M6.39162 8.83672C6.96518 10.0093 7.91569 10.9555 9.09091 11.5237C9.17689 11.5645 9.272 11.5821 9.36688 11.575C9.46176 11.5678 9.55314 11.536 9.63201 11.4828L11.3624 10.3289C11.439 10.2779 11.527 10.2468 11.6186 10.2383C11.7102 10.2299 11.8025 10.2445 11.887 10.2807L15.1243 11.6681C15.2343 11.7149 15.3261 11.796 15.3859 11.8995C15.4457 12.0029 15.4703 12.123 15.4559 12.2416C15.3536 13.0423 14.963 13.7782 14.3571 14.3116C13.7513 14.8449 12.9718 15.1392 12.1646 15.1392C9.67156 15.1392 7.28062 14.1489 5.51777 12.386C3.75492 10.6232 2.76456 8.23222 2.76456 5.73917C2.7646 4.93199 3.05886 4.15251 3.59223 3.54666C4.12561 2.94082 4.86153 2.55016 5.66219 2.44784C5.78081 2.43349 5.90088 2.45807 6.00431 2.51789C6.10774 2.5777 6.18893 2.66951 6.23564 2.77948L7.62426 6.01959C7.66018 6.1034 7.67481 6.19479 7.66686 6.28562C7.65891 6.37646 7.62862 6.46391 7.57869 6.54021L6.42882 8.29717C6.37648 8.3762 6.34554 8.46747 6.33902 8.56203C6.3325 8.6566 6.35062 8.75125 6.39162 8.83672Z"
      fill="#26C165"
    />
    <path
      d="M6.39162 8.83672C6.96518 10.0093 7.91569 10.9555 9.09091 11.5237C9.17689 11.5645 9.272 11.5821 9.36688 11.575C9.46176 11.5678 9.55314 11.536 9.63201 11.4828L11.3624 10.3289C11.439 10.2779 11.527 10.2468 11.6186 10.2383C11.7102 10.2299 11.8025 10.2445 11.887 10.2807L15.1243 11.6681C15.2343 11.7149 15.3261 11.796 15.3859 11.8995C15.4457 12.0029 15.4703 12.123 15.4559 12.2416C15.3536 13.0423 14.963 13.7782 14.3571 14.3116C13.7513 14.8449 12.9718 15.1392 12.1646 15.1392C9.67156 15.1392 7.28062 14.1489 5.51777 12.386C3.75492 10.6232 2.76456 8.23222 2.76456 5.73917C2.7646 4.93199 3.05886 4.15251 3.59223 3.54666C4.12561 2.94082 4.86153 2.55016 5.66219 2.44784C5.78081 2.43349 5.90088 2.45807 6.00431 2.51789C6.10774 2.5777 6.18893 2.66951 6.23564 2.77948L7.62426 6.01959C7.66018 6.1034 7.67481 6.19479 7.66686 6.28562C7.65891 6.37646 7.62862 6.46391 7.57869 6.54021L6.42882 8.29717C6.37648 8.3762 6.34554 8.46747 6.33902 8.56203C6.3325 8.6566 6.35062 8.75125 6.39162 8.83672V8.83672Z"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0155 2.9707C11.9529 3.2228 12.8076 3.71678 13.4939 4.40315C14.1803 5.08953 14.6743 5.94421 14.9264 6.88158"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4456 5.10498C11.0081 5.25624 11.5209 5.55263 11.9327 5.96445C12.3445 6.37628 12.6409 6.88909 12.7922 7.45151"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "phoneCall"
};
</script>

<style></style>
