import sycamoreServiceClient from ".";

export const getSycamoreProducts = async () => {
    const response = await sycamoreServiceClient({
        url: "/misc/offers/products",
        method: "get"
    });

    return {
        status: response.status,
        data: response.data
    };
};

