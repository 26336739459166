<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8387 12.2948C11.6626 12.2948 11.4937 12.2248 11.3692 12.1003C11.2447 11.9758 11.1748 11.807 11.1748 11.6309V5.6561H5.2C5.02393 5.6561 4.85507 5.58616 4.73057 5.46166C4.60608 5.33716 4.53613 5.1683 4.53613 4.99223C4.53613 4.81617 4.60608 4.64731 4.73057 4.52281C4.85507 4.39831 5.02393 4.32837 5.2 4.32837H11.8387C12.0147 4.32837 12.1836 4.39831 12.3081 4.52281C12.4326 4.64731 12.5025 4.81617 12.5025 4.99223V11.6309C12.5025 11.807 12.4326 11.9758 12.3081 12.1003C12.1836 12.2248 12.0147 12.2948 11.8387 12.2948Z"
      fill="#E23939"
    />
    <path
      d="M5.19986 12.2946C5.06858 12.2946 4.94025 12.2556 4.83111 12.1827C4.72196 12.1097 4.63689 12.006 4.58666 11.8847C4.53642 11.7635 4.52328 11.63 4.54888 11.5012C4.57448 11.3725 4.63769 11.2542 4.7305 11.1614L11.3692 4.52271C11.494 4.40009 11.6623 4.33173 11.8373 4.33253C12.0123 4.33332 12.1799 4.40319 12.3036 4.52694C12.4274 4.65069 12.4973 4.8183 12.498 4.99331C12.4988 5.16831 12.4305 5.33655 12.3079 5.46141L5.66921 12.1001C5.60765 12.1618 5.53449 12.2108 5.45395 12.2442C5.3734 12.2775 5.28705 12.2947 5.19986 12.2946Z"
      fill="#E23939"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpRightRed"
};
</script>

<style></style>
