<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.875 0.75H2.125C1.76046 0.750417 1.41096 0.895417 1.15319 1.15319C0.895417 1.41096 0.750417 1.76046 0.75 2.125V15.875C0.750417 16.2395 0.895417 16.589 1.15319 16.8468C1.41096 17.1046 1.76046 17.2496 2.125 17.25H15.875C16.2395 17.2496 16.589 17.1046 16.8468 16.8468C17.1046 16.589 17.2496 16.2395 17.25 15.875V2.125C17.2496 1.76046 17.1046 1.41096 16.8468 1.15319C16.589 0.895417 16.2395 0.750417 15.875 0.75ZM4.875 15.875H2.125V2.125H4.875V15.875ZM13.125 11.0625H7.625C7.44266 11.0625 7.2678 10.9901 7.13886 10.8611C7.00993 10.7322 6.9375 10.5573 6.9375 10.375C6.9375 10.1927 7.00993 10.0178 7.13886 9.88886C7.2678 9.75993 7.44266 9.6875 7.625 9.6875H13.125C13.3073 9.6875 13.4822 9.75993 13.6111 9.88886C13.7401 10.0178 13.8125 10.1927 13.8125 10.375C13.8125 10.5573 13.7401 10.7322 13.6111 10.8611C13.4822 10.9901 13.3073 11.0625 13.125 11.0625ZM13.125 8.3125H7.625C7.44266 8.3125 7.2678 8.24007 7.13886 8.11114C7.00993 7.9822 6.9375 7.80734 6.9375 7.625C6.9375 7.44266 7.00993 7.2678 7.13886 7.13886C7.2678 7.00993 7.44266 6.9375 7.625 6.9375H13.125C13.3073 6.9375 13.4822 7.00993 13.6111 7.13886C13.7401 7.2678 13.8125 7.44266 13.8125 7.625C13.8125 7.80734 13.7401 7.9822 13.6111 8.11114C13.4822 8.24007 13.3073 8.3125 13.125 8.3125Z"
      fill="#27E7A2"
    />
  </svg>
</template>

<script>
export default {
  name: "MenuKnowledgeBase"
};
</script>
