<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1526 9.25502L3.74156 0.877451L14.4428 6.97997L12.1526 9.25502ZM1.54705 0.38501C1.05131 0.642436 0.720215 1.11186 0.720215 1.7212V18.4273C0.720215 19.0366 1.05192 19.506 1.54705 19.7635L11.3263 10.0721L1.54705 0.38501ZM17.7507 8.92551L15.5062 7.63474L13.0026 10.0764L15.5062 12.518L17.7965 11.2272C18.4825 10.6863 18.4825 9.46701 17.7507 8.92551V8.92551ZM3.74217 19.2759L14.4434 13.1733L12.1532 10.8983L3.74217 19.2759Z"
      fill="#073042"
    />
  </svg>
</template>

<script>
export default {
  name: "Playstore"
};
</script>
