<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5625 3.5H13.0666C12.9043 2.53886 12.4068 1.66627 11.6624 1.03701C10.918 0.40775 9.97475 0.0625 9 0.0625C8.02525 0.0625 7.08201 0.40775 6.33758 1.03701C5.59316 1.66627 5.09567 2.53886 4.93337 3.5H1.4375C1.07296 3.50042 0.723461 3.64542 0.465689 3.90319C0.207917 4.16096 0.0629169 4.51045 0.0625 4.875V15.875C0.0629169 16.2395 0.207917 16.589 0.465689 16.8468C0.723461 17.1046 1.07296 17.2496 1.4375 17.25H16.5625C16.927 17.2496 17.2765 17.1046 17.5343 16.8468C17.7921 16.589 17.9371 16.2395 17.9375 15.875V4.875C17.9371 4.51045 17.7921 4.16096 17.5343 3.90319C17.2765 3.64542 16.927 3.50042 16.5625 3.5ZM6.24933 6.9375C6.24933 7.11983 6.1769 7.2947 6.04797 7.42363C5.91903 7.55257 5.74416 7.625 5.56183 7.625C5.37949 7.625 5.20462 7.55257 5.07569 7.42363C4.94676 7.2947 4.87433 7.11983 4.87433 6.9375V5.5625C4.87433 5.38016 4.94676 5.20529 5.07569 5.07636C5.20462 4.94743 5.37949 4.875 5.56183 4.875C5.74416 4.875 5.91903 4.94743 6.04797 5.07636C6.1769 5.20529 6.24933 5.38016 6.24933 5.5625V6.9375ZM6.33703 3.5C6.48952 2.90968 6.83383 2.38676 7.31586 2.01342C7.79789 1.64009 8.3903 1.4375 9 1.4375C9.6097 1.4375 10.2021 1.64009 10.6841 2.01342C11.1662 2.38676 11.5105 2.90968 11.663 3.5H6.33703ZM13.1243 6.9375C13.1243 7.11983 13.0519 7.2947 12.923 7.42363C12.794 7.55257 12.6192 7.625 12.4368 7.625C12.2545 7.625 12.0796 7.55257 11.9507 7.42363C11.8218 7.2947 11.7493 7.11983 11.7493 6.9375V5.5625C11.7493 5.38016 11.8218 5.20529 11.9507 5.07636C12.0796 4.94743 12.2545 4.875 12.4368 4.875C12.6192 4.875 12.794 4.94743 12.923 5.07636C13.0519 5.20529 13.1243 5.38016 13.1243 5.5625V6.9375Z"
      fill="#3860FF"
    />
  </svg>
</template>

<script>
export default {
  name: "MenuInvestment"
};
</script>
