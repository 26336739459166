<template>
  <svg
    width="59"
    height="60"
    viewBox="0 0 59 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.7"
      d="M16.5938 23.8699H7.375C6.35672 23.8699 5.53125 24.6899 5.53125 25.7014V34.8589C5.53125 35.8704 6.35672 36.6904 7.375 36.6904H16.5938C17.612 36.6904 18.4375 35.8704 18.4375 34.8589V25.7014C18.4375 24.6899 17.612 23.8699 16.5938 23.8699Z"
      fill="#FFE9D6"
    />
    <path
      opacity="0.7"
      d="M49.7812 10.1335H38.7188C37.7005 10.1335 36.875 10.9535 36.875 11.9651V22.9541C36.875 23.9656 37.7005 24.7856 38.7188 24.7856H49.7812C50.7995 24.7856 51.625 23.9656 51.625 22.9541V11.9651C51.625 10.9535 50.7995 10.1335 49.7812 10.1335Z"
      fill="#FFE9D6"
    />
    <path
      opacity="0.7"
      d="M49.7812 35.7747H38.7188C37.7005 35.7747 36.875 36.5947 36.875 37.6062V48.5952C36.875 49.6067 37.7005 50.4267 38.7188 50.4267H49.7812C50.7995 50.4267 51.625 49.6067 51.625 48.5952V37.6062C51.625 36.5947 50.7995 35.7747 49.7812 35.7747Z"
      fill="#FFE9D6"
    />
    <path
      d="M16.5938 23.8699H7.375C6.35672 23.8699 5.53125 24.6899 5.53125 25.7014V34.8589C5.53125 35.8704 6.35672 36.6904 7.375 36.6904H16.5938C17.612 36.6904 18.4375 35.8704 18.4375 34.8589V25.7014C18.4375 24.6899 17.612 23.8699 16.5938 23.8699Z"
      stroke="#FF7600"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.7812 10.1335H38.7188C37.7005 10.1335 36.875 10.9535 36.875 11.9651V22.9541C36.875 23.9656 37.7005 24.7856 38.7188 24.7856H49.7812C50.7995 24.7856 51.625 23.9656 51.625 22.9541V11.9651C51.625 10.9535 50.7995 10.1335 49.7812 10.1335Z"
      stroke="#FF7600"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.7812 35.7747H38.7188C37.7005 35.7747 36.875 36.5947 36.875 37.6062V48.5952C36.875 49.6067 37.7005 50.4267 38.7188 50.4267H49.7812C50.7995 50.4267 51.625 49.6067 51.625 48.5952V37.6062C51.625 36.5947 50.7995 35.7747 49.7812 35.7747Z"
      stroke="#FF7600"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4375 30.28H27.6562"
      stroke="#FF7600"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.875 43.1006H33.1875C31.7205 43.1006 30.3136 42.5217 29.2763 41.4913C28.239 40.4609 27.6562 39.0633 27.6562 37.6061V22.954C27.6562 21.4968 28.239 20.0992 29.2763 19.0688C30.3136 18.0384 31.7205 17.4595 33.1875 17.4595H36.875"
      stroke="#FF7600"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "TreeStructure"
};
</script>
