<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25992 11.3472C7.55242 11.3472 8.58825 12.3931 8.58825 13.6948V16.8197C8.58825 18.1122 7.55242 19.1664 6.25992 19.1664H3.16159C1.87825 19.1664 0.833252 18.1122 0.833252 16.8197V13.6948C0.833252 12.3931 1.87825 11.3472 3.16159 11.3472H6.25992ZM16.8383 11.3472C18.1217 11.3472 19.1667 12.3931 19.1667 13.6948V16.8197C19.1667 18.1122 18.1217 19.1664 16.8383 19.1664H13.74C12.4475 19.1664 11.4117 18.1122 11.4117 16.8197V13.6948C11.4117 12.3931 12.4475 11.3472 13.74 11.3472H16.8383ZM6.25992 0.833336C7.55242 0.833336 8.58825 1.8875 8.58825 3.18092V6.30584C8.58825 7.6075 7.55242 8.6525 6.25992 8.6525H3.16159C1.87825 8.6525 0.833252 7.6075 0.833252 6.30584V3.18092C0.833252 1.8875 1.87825 0.833336 3.16159 0.833336H6.25992ZM16.8383 0.833336C18.1217 0.833336 19.1667 1.8875 19.1667 3.18092V6.30584C19.1667 7.6075 18.1217 8.6525 16.8383 8.6525H13.74C12.4475 8.6525 11.4117 7.6075 11.4117 6.30584V3.18092C11.4117 1.8875 12.4475 0.833336 13.74 0.833336H16.8383Z"
      fill="#26C165"
    />
  </svg>
</template>
<script>
export default {
  name: "MenuAssetFinancing"
};
</script>
