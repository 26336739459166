<template>
	<div id="app">
        <div v-if="!showNav">
            <router-view />
        </div>
        
        <div v-else>
            <div v-if="cookiesSet == false">
                <Cookies @cookieIsSet="hideCookie($event)" />
            </div>

            <div id="nav" v-if="$route.name != 'Referral'">
                <div id="md">
                    <Navbar />
                </div>

                <div id="sm">
                    <Nav />
                </div>
            </div>

            <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
            >
                <router-view />
            </transition>

            <Footer v-if="$route.name !== 'Referral'" />
        </div>
	</div>
</template>

<script>
	import Navbar from "./components/Navbar";
	import Nav from "./components/Nav";
	import Footer from "./components/Footer";
	import Cookies from "./components/Cookies";
    import { mapState, mapActions } from 'pinia'
    import { sycamoreContacts } from './store/index'

	export default {
		components: {
			Navbar,
			Nav,
			Footer,
			Cookies
		},
		
		data() {
			return {
				cookiesSet: false
			};
		},

        computed: {
            ...mapState(sycamoreContacts, ["isContactsSet"]),
			showNav() {
				const excludedPages = ['AssetFinancing', 'Quickly', 'CampusAmbassadors'];
				if (!excludedPages.includes(this.$route.name)) {
					return true;
				}
				return false;

			}
        },

		methods: {
			hideCookie(isSet) {
				this.cookiesSet = isSet;
			},

            ...mapActions(sycamoreContacts, ['getContacts', 'getProductOffer']),
		},

		mounted() {
			const decodedCookie = decodeURIComponent(document.cookie);
			const ca = decodedCookie.split(";");
			const c = ca.filter(item => item == " Sycamore");

			if (c.length != 0) {
				this.cookiesSet = true;
				// vm.$forceUpdate();
			}
		},

        created(){
            if (!this.isContactsSet) {
                this.getContacts();
            }
			if (!this.isFetchedOffers) {
                this.getProductOffer();
            }
        }
	};
</script>

<style scoped>
	@media only screen and (min-width: 760px) {
		#md {
			display: block;
		}
		#sm {
			display: none;
		}
	}

	@media only screen and (max-width: 759px) {
		#md {
			display: none;
		}
		#sm {
			display: block;
		}
	}
</style>
