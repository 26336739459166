import sycamoreServiceClient from ".";

export const getContacts = async () => {
    const response = await sycamoreServiceClient({
        url: "/customer/sycamore/variables",
        method: "get"
    });

    return {
        status: response.status,
        data: response.data
    };
};

