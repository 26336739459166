/* eslint-disable vue/multi-word-component-names */
import Vue from "vue";
import Toast, {TYPE} from "vue-toastification";
import App from "./App.vue";
import router from "./router";
import particles from "@/components/Particles.vue";
import "vue-toastification/dist/index.css";
import "./assets/css/tailwind.css";
import "animate.css";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueMeta from 'vue-meta';

const toastOptions = {
	closeButtonClassName: 'custom-syc-toast-close',
	bodyClassName: 'custom-syc-toast-body',
	toastDefaults: {
		[TYPE.DEFAULT]: {
			toastClassName: 'custom-syc-toast',
			
		},
		[TYPE.SUCCESS]: {
			toastClassName: 'custom-syc-success-toast',
		},
		[TYPE.ERROR]: {
			toastClassName: 'custom-syc-error-toast',
		},
	}
};

Vue.config.productionTip = false;
Vue.component("particles", particles);
Vue.use(Toast, toastOptions);
Vue.use(VueMeta);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(pinia);


new Vue({
	router,
    pinia,
	render: (h) => h(App),
}).$mount("#app");
