<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M8.84732 2.18457C7.38082 2.18457 5.97438 2.76714 4.93741 3.80411C3.90044 4.84108 3.31787 6.24752 3.31787 7.71402C3.31787 12.6905 8.84732 16.5611 8.84732 16.5611C8.84732 16.5611 14.3768 12.6905 14.3768 7.71402C14.3768 6.24752 13.7942 4.84108 12.7572 3.80411C11.7202 2.76714 10.3138 2.18457 8.84732 2.18457ZM8.84732 9.92579C8.40987 9.92579 7.98224 9.79607 7.61852 9.55304C7.25479 9.31001 6.9713 8.96458 6.8039 8.56043C6.6365 8.15628 6.59269 7.71156 6.67804 7.28252C6.76338 6.85348 6.97403 6.45937 7.28335 6.15005C7.59268 5.84073 7.98678 5.63008 8.41582 5.54474C8.84486 5.45939 9.28958 5.50319 9.69373 5.6706C10.0979 5.838 10.4433 6.12149 10.6863 6.48522C10.9294 6.84894 11.0591 7.27657 11.0591 7.71402C11.0591 8.30061 10.8261 8.86318 10.4113 9.27797C9.99648 9.69276 9.43391 9.92579 8.84732 9.92579Z"
      fill="#26C165"
    />
    <path
      d="M3.87073 16.5615H13.8237"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.84709 9.92649C10.0686 9.92649 11.0589 8.93624 11.0589 7.71471C11.0589 6.49318 10.0686 5.50293 8.84709 5.50293C7.62556 5.50293 6.63531 6.49318 6.63531 7.71471C6.63531 8.93624 7.62556 9.92649 8.84709 9.92649Z"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3768 7.71402C14.3768 12.6905 8.84732 16.5611 8.84732 16.5611C8.84732 16.5611 3.31787 12.6905 3.31787 7.71402C3.31787 6.24752 3.90044 4.84108 4.93741 3.80411C5.97438 2.76714 7.38082 2.18457 8.84732 2.18457C10.3138 2.18457 11.7203 2.76714 12.7572 3.80411C13.7942 4.84108 14.3768 6.24752 14.3768 7.71402V7.71402Z"
      stroke="#26C165"
      stroke-width="0.931275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "location"
};
</script>

<style></style>
